import { stepInterface } from "../_models/eservice.interface";

export const contractForm:any=[
    {
        key:"",
        type:"text",
        name:"npi",
        label:"NPI",
        value:null,
        model:false,
        helper_text:"",
        canUpdate:false,
        required:true,
        oldValues:[]
    },
    {
        key:"",
        type:"text",
        name:"identity",
        label:"Nom et prénom(s)",
        value:null,
        model:false,
        helper_text:"",
        canUpdate:false,
        required:true,
        oldValues:[]
    },
    {
        key:"",
        type:"text",
        name:"nationality",
        label:"Nationalité",
        value:null,
        model:false,
        helper_text:"",
        canUpdate:false,
        required:true,
        oldValues:[]
    },
    {
        key:"",
        type:"text",
        name:"speciality",
        label:"Diplôme de recrutement",
        value:null,
        model:false,
        helper_text:"",
        canUpdate:false,
        required:true,
        oldValues:[]
    },
    {
        key:"nature_id",
        type:"model",
        name:"nature",
        label:"Nature contrat",
        value:null,
        model:true,
        helper_text:"",
        canUpdate:false,
        required:true,
        oldValues:[]
    },
    {
        title:"Charger le contrat",
        description:"",
        file64:null,
        filename:"",
        canUpdate:false,
        required:true,
        isSetted:true,
        oldValues:[]
        }
]

export const VcnForm:stepInterface[]=[
    {
        name:"Type de demandeur",
        is_active:true,
        description:"Êtes vous une personne physique ou morale ? (Sélectionnez l'icône correspondant selon votre choix!)",
        inputs:[
            {
                key:"",
                type:"design1",
                name:"type",
                label:"",
                labels:[
                    {
                        name:"Personne physique",
                        value:"physique"
                    },
                    {
                        name:"Personne morale",
                        value:"morale"
                    }
                ],
                value:null,
                model:false,
                helper_text:"",
                canUpdate:false,
                required:true,
                oldValues:[]
            },
            {
                key:"",
                type:"text",
                name:"has_account",
                label:"Employer ou structure déjà répertorié",
                value:false,
                model:false,
                helper_text:"",
                canUpdate:false,
                required:true,
                oldValues:[]
            },
            {
                key:"",
                type:"text",
                name:"code",
                label:"Code",
                value:null,
                model:false,
                helper_text:"",
                canUpdate:false,
                required:true,
                oldValues:[]
            },
            {
                key:"",
                type:"text",
                name:"npi_requester",
                label:"Veuillez renseigner le NPI de l'employeur",
                value:null,
                model:false,
                helper_text:"",
                canUpdate:false,
                required:true,
                oldValues:[]
            },
            {
                key:"",
                type:"text",
                name:"ifu_structure",
                label:"Veuillez renseigner l'IFU de l'entreprise",
                value:null,
                model:false,
                helper_text:"",
                canUpdate:false,
                required:true,
                oldValues:[]
            }
        ]
    },
    {
        name:"Informations sur le soumissionnaire",
        is_active:true,
        description:"",
        inputs:[
            {
                key:"",
                type:"text",
                name:"identity_requester",
                label:"Nom et prénom(s)",
                value:null,
                model:false,
                helper_text:"",
                canUpdate:false,
                required:true,
                oldValues:[]
            },
            {
                key:"",
                type:"text",
                name:"quality_requester",
                label:"Qualité",
                value:null,
                model:false,
                helper_text:"",
                canUpdate:false,
                required:true,
                oldValues:[]
            },
            {
                key:"",
                type:"text",
                name:"phone_requester",
                label:"Téléphone",
                value:null,
                model:false,
                helper_text:"",
                canUpdate:false,
                required:true,
                oldValues:[]
            },
            {
                key:"",
                type:"email",
                name:"email_requester",
                label:"Email",
                value:null,
                model:false,
                helper_text:"",
                canUpdate:false,
                required:true,
                oldValues:[]
            },
            {
                key:"",
                type:"radio",
                name:"requester_is_respo",
                label:"Êtes vous aussi l'employeur ?",
                labels:[
                    {
                    name:"Oui",
                    value:"1"
                    },
                    {
                        name:"Non",
                        value:"0"
                    }
                ],
                value:null,
                model:false,
                helper_text:"",
                canUpdate:false,
                required:true,
                oldValues:[]
            }
        ]
    },
    {
        name:"Informations sur l'employeur",
        is_active:true,
        description:"",
        inputs:[
            {
                key:"",
                type:"text",
                name:"identity_respo",
                label:"Nom et prénom(s) de l'employeur",
                value:null,
                model:false,
                helper_text:"",
                canUpdate:false,
                required:true,
                oldValues:[]
            },
            {
                key:"",
                type:"text",
                name:"job_respo",
                label:"Titre/poste",
                value:null,
                model:false,
                helper_text:"",
                canUpdate:false,
                required:true,
                oldValues:[]
            },
            {
                key:"",
                type:"text",
                name:"phone_respo",
                label:"Téléphone",
                value:null,
                model:false,
                helper_text:"",
                canUpdate:false,
                required:true,
                oldValues:[]
            },
            {
                key:"",
                type:"email",
                name:"email_respo",
                label:"Email",
                value:null,
                model:false,
                helper_text:"",
                canUpdate:false,
                required:true,
                oldValues:[]
            },
            {
                key:"department_id",
                type:"model",
                name:"department_respo",
                label:"Département",
                value:null,
                model:false,
                helper_text:"",
                canUpdate:false,
                required:true,
                oldValues:[]
            },
            {
                key:"municipality_id",
                type:"model",
                name:"municipality_respo",
                label:"Commune",
                value:null,
                model:false,
                helper_text:"",
                canUpdate:false,
                required:true,
                oldValues:[]
            },
            {
                key:"city_id",
                type:"model",
                name:"city_respo",
                label:"Ville",
                value:null,
                model:false,
                helper_text:"",
                canUpdate:false,
                required:true,
                oldValues:[]
            },
            {
                key:"",
                type:"text",
                name:"district_structure",
                label:"Quartier",
                value:null,
                model:false,
                helper_text:"",
                canUpdate:false,
                required:true,
                oldValues:[]
            }
        ],
    files:[
        {
        title:"Pièce d'identification (IFU,RCCM,CNI,CIP,...)",
        description:"",
        file64:null,
        filename:"",
        canUpdate:false,
        required:true,
        isSetted:false,
        oldValues:[]
        }
    ] 
    },
    {
        name:"Informations sur la structure",
        is_active:false,
        description:"",
        inputs:[
            {
                key:"",
                type:"text",
                name:"name_structure",
                label:"Nom de la structure",
                value:null,
                model:false,
                helper_text:"",
                canUpdate:false,
                required:true,
                oldValues:[]
            },
            {
                key:"type_structure_id",
                type:"text",
                name:"type_structure",
                label:"Type de la structure",
                value:null,
                model:true,
                helper_text:"",
                canUpdate:false,
                required:true,
                oldValues:[]
            },
            {
                key:"",
                type:"text",
                name:"phone_structure",
                label:"Téléphone",
                value:null,
                model:false,
                helper_text:"",
                canUpdate:false,
                required:true,
                oldValues:[]
            },
            {
                key:"",
                type:"email",
                name:"email_structure",
                label:"Email",
                value:null,
                model:false,
                helper_text:"",
                canUpdate:false,
                required:true,
                oldValues:[]
            },
            {
                key:"department_id",
                type:"model",
                name:"department_respo",
                label:"Département",
                value:null,
                model:false,
                helper_text:"",
                canUpdate:false,
                required:true,
                oldValues:[]
            },
            {
                key:"municipality_id",
                type:"model",
                name:"municipality_respo",
                label:"Commune",
                value:null,
                model:false,
                helper_text:"",
                canUpdate:false,
                required:true,
                oldValues:[]
            },
            {
                key:"city_id",
                type:"model",
                name:"city_respo",
                label:"Ville",
                value:null,
                model:true,
                helper_text:"",
                canUpdate:false,
                required:true,
                oldValues:[]
            },
            {
                key:"",
                type:"text",
                name:"district_structure",
                label:"Quartier",
                value:null,
                model:false,
                helper_text:"",
                canUpdate:false,
                required:true,
                oldValues:[]
            }
        ],
    files:[
        {
        title:"Pièce d'identification (IFU,RCCM,CNI,CIP,...)",
        description:"",
        file64:null,
        filename:"",
        canUpdate:false,
        required:true,
        isSetted:false,
        oldValues:[]
        }
    ] 
    },
    {
        name:"Contrats à soumettre pour visa",
        is_active:true,
        description:"",
        contracts:[]
    },
]