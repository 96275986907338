import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from '../../app.config';

@Injectable({
  providedIn: 'root'
})
export class ReportTransmissionService {
  url=Config.toMatApiUrl();

  constructor(private http:HttpClient) { }

    getAll(type?:any){
    return this.http.get<any[]>(`${this.url}/report-transmissions/?type=${type}`, Config.httpHeader(localStorage.getItem("matToken"),true));
  }
 

  store(ressource:any){
    return this.http.post<any>(`${this.url}/report-transmissions`, ressource,
      Config.httpHeader(localStorage.getItem("matToken"),true));
  }

  update(id:any,ressource:any){
    ressource['_method']='patch';
    //ressource.append('_method','patch');

    return this.http.post<any>(`${this.url}/report-transmissions/${id}`, ressource,   Config.httpHeader(localStorage.getItem("matToken"),true));
  }
  delete(id:any){
    return this.http.delete<any>(`${this.url}/report-transmissions/${id}`,
      Config.httpHeader(localStorage.getItem("matToken"),true));
  }

  get(id:any){
    return this.http.get<any>(`${this.url}/report-transmissions/${id}`,
    Config.httpHeader(localStorage.getItem("matToken"),true));
  }
}
