import { Injectable } from '@angular/core';
import { Config } from '../../app.config';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RegistreService {
  url = Config.toMatApiUrl();

  constructor(private http: HttpClient) { }

  getAll(startDate:any,endDate:any,sex?:any){
    return this.http.get<any[]>(`${this.url}/registre?startDate=${startDate}&endDate=${endDate}&sex=${sex}`, Config.httpHeader(localStorage.getItem("mataccueilToken"),true));
  }

}
