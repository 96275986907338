export const environment = {
    production: true,
    isMockEnabled: true, // You have to switch this, when your real back-end is done
    API_SCHEME: 'https',
    API_DOMAIN: 'back.guvmtfp.gouv.bj/api',
    API_FILE: 'back.guvmtfp.gouv.bj',
    API_VERSION: 'v1',
    MAT_API_DOMAIN:"preprodmtfp.gouv.bj/pprod-mataccueilapi/api",
    MAT_FILE_DOMAIN:"preprodmtfp.gouv.bj/pprod-mataccueilapi",
    BO_API_DOMAIN:"preprodmtfp.gouv.bj/pprod-boapi/api",
    recaptcha: {
        siteKey: '6Leor0sdAAAAANNhcT-okRExOrfRxP-M0dsFvLAD',
        secret :'6Leor0sdAAAAAEeZn4K4qZvYTRVqrpfJYFz2TJIR'
    },
};