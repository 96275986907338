<section class="o-banner">
        <div class="card text-white rounded-0">
      
            <img src="assets/bg-new.jpeg" class="card-img" height="120" alt="...">
            <div class="card-img-overlay">
      
                <div class="text container">
                    <h2 class="card-title text-center">ESPACE POINT FOCAL COMMUNAL</h2> 
                    <h6 class="card-title text-center">Enregistrement des visites et des préoccupations des usagers du centre communal</h6> 
                    <p></p>
                </div>
            </div>
        </div>
      
</section>


<div class="d-flex justify-content-around" style="background-color: #0A3764">
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs justify-content-center">
        <li [ngbNavItem]="1">
            <a ngbNavLink>Mataccueil</a>
            <ng-template ngbNavContent>
                <div class="row m-3">
                    <div class="col-md-12 mx-auto">
                        <p class="text-center"> <strong>Enregistrement des demandes informations et des plaintes </strong> </p>
                        <div class="col-md-12 ml-2 mr-2">
                            <div class="card my-2">
                                <div class="card-header border-0">
                                    <div class="card-toolbar d-flex justify-content-between mt-4">
                                        <ul class="nav nav-pills ml-2 nav-pills-sm nav-dark-75">
                                            <li class="nav-item">
                                                <span class="mr-2">{{dataReq.length}} élement(s) </span>
                                            </li>
                                            <li class="nav-item mr-2">
                                                <form>
                                                    <div class="form-group form-inline">
                                                        <input class="form-control form-control form-control-sm form-control-sm-sm "
                                                            placeholder="Rechercher...." type="text" [(ngModel)]="searchTextReq"
                                                            name="searchTextReq" (keyup)="searchReq()" />
                                                    </div>
                                                </form>
                                            </li>
                                            <li class="nav-item">
                                                <span class="mr-2">Statut : </span>
                                            </li>
                                            <li class="nav-item">
                                                <div class="form-group form-inline">
                                                    <div>
                                                        <select class="form-control form-control-sm form-control-sm-sm " name="selected_Status" (change)="searchReq()" [(ngModel)]="selected_Status" id="">
                                                            <option value="0">En cours</option>
                                                            <option value="1">Traitée</option>
                                                            </select>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                        <div>
                                            <button (click)="openEditModalAdd(addRequete)" class=" mx-1 btn btn-sm btn-info">Ajouter Préoccupation</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body pt-3 pb-0">
                                    <!--begin::Table-->
                                    <div class="table-responsive">
                        
                                    <table class="table table-bordered table-striped datatable  table-head-custom table-vertical-center"
                                        id="kt_datatable">
                                        <thead>
                                            <tr>
                                                <td class="left text-left" colspan="15">
                                                    <button *ngIf="show_actions" (click)="openEditModalLg(edit)" title="Modifier la requête" class = "btn mx-1 btn-xs btn-warning edit">Modifier
                                                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                                        </svg> 
                                                    </button> 
                                                                       
                                                    <button *ngIf="show_actions" title="Supprimer à la requête" (click)="dropRequeteusager()" class="btn mx-1 btn-xs btn-danger edit">Supprimer
                                                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                        </svg> 
                                                    </button>
                                                        
                                                    <button *ngIf="show_actions" title="Transmettre la requête" (click)="transmettreRequete()" class="btn mx-1 btn-xs btn-success"> Transmettre
                                                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-90deg-right"
                                                            fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" d="M14.854 4.854a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 4H3.5A2.5 2.5 0 0 0 1 6.5v8a.5.5 0 0 0 1 0v-8A1.5 1.5 0 0 1 3.5 5h9.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4z" />
                                                        </svg> 
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th></th>
                                                <th width="7%">Date Enreg.</th>
                                                <th width="10%">Type</th>
                                                <th width="15%">Prestation</th>
                                                <th width="15%">Objet</th>
                                                <th width="15%">Message</th>
                                                <th width="15%">Contact / E-mail</th>
                                                <th width="15%">Statut</th>
                        
                                                <th>Notification</th>
                                                <!--<th width="20%">Durée Traitement</th>-->
                                                <th width="10%">Appréciation.</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let elr of dataReq | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; index as i">
                                                <td >
                                                    <span *ngIf="elr.visible==0"><input type="radio" [value]="elr" ngModel name="getrequete" (change)="checkedReq($event,elr)"></span>
                                                </td>
                                                <td>{{elr.created_at | date:'dd/MM/yyy'}} </td>
                                                <td>
                                                    <!-- <span *ngIf="elr.plainte==0">Requête</span> -->
                                                    <span *ngIf="elr.plainte==1">Plainte</span>
                                                    <span *ngIf="elr.plainte==2">Demande d'information</span>
                                                </td>
                                                <td>{{elr.service==null ? '' : elr.service.libelle}} </td>
                                                <td>{{elr.objet}} </td>
                                                <td>{{elr.msgrequest}} </td>
                                                <td>{{elr.contact}}{{elr.email==null ? '' : " / "+elr.email}} {{(elr.matricule=='' || elr.matricule== null) ? '' : " / Matricule : "+elr.matricule}}</td>
                                                <td>
                                                    <span *ngIf="elr.visible==0">1. En attente de transmission</span>
                                                    <span *ngIf="elr.visible==1">1. En cours de traitement au {{elr.entite==null ? '--' : elr.entite.sigle}}</span>
                        
                                                    <div *ngFor="let parc of elr.parcours; let i=index">
                        
                                                        <!-- <span *ngIf="i==0" style="display:none">{{i+1}}</span> -->
                                                        <span *ngIf="i>=1">{{i+1}}. {{ show_step(parc.idEtape)==null ? '' : show_step(parc.idEtape).LibelleEtapeUs }}</span>
                                                    </div>
                                                    
                                                    <!--span *ngIf="elr.visible==1 && elr.traiteOuiNon==0  && elr.rejete==0 && elr.interrompu==0">En
                                                        cours de traitement {{elr.entite.sigle}} </span-->
                                                    <span *ngIf="elr.visible==1 && elr.traiteOuiNon==1  && elr.rejete==1">Rejeté</span>
                                                    <span *ngIf="elr.visible==1 && elr.traiteOuiNon==1 && elr.interrompu==1">Interrompu</span>
                                                    <span *ngIf="elr.visible==1 && elr.visible==1 && elr.traiteOuiNon==1  && elr.rejete==0 && elr.interrompu==0">Finalisé</span>
                                                </td>
                        
                                                <td>{{elr.reponseStructure}} 
                                                    <span *ngIf="elr.fichier_joint">Fichier réponse : <a target="_blank" (click)="ChangerFile(elr.fichier_joint)" href="#">{{elr.fichier_joint}}</a></span>
                                                </td>
                                                <!--<td>{{Date.dateDiff('d',s.created_at,s.dateReponse)}} </td>-->
                                                <td>
                                                    <button (click)="openNoteModal(note,elr)" *ngIf="elr.traiteOuiNon==1 && elr.noteUsager==NULL"
                                                        title="Apprécier la prestation" class="btn btn-xs btn-danger edit"><svg
                                                            width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil"
                                                            fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                                        </svg> </button>
                                                        <span *ngIf="elr.noteUsager!=NULL">{{elr.noteUsager}}</span>   
                        
                                                </td>
                        
                                            </tr>
                                            <tr *ngIf="dataReq.length==0">
                                                <td colspan="10" class="text-center bg-gray-100">Aucun élément</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="d-flex justify-content-between p-2">
                                        <ngb-pagination class="" [collectionSize]="collectionSize" [(page)]="page"
                                            [maxSize]="5" [pageSize]="pageSize" [rotate]="true" [ellipses]="false"
                                            [boundaryLinks]="true">
                                        </ngb-pagination>
                        
                                        <select class="custom-select custom-select-sm float-right" style="width: auto"
                                            [(ngModel)]="pageSize">
                                            <option selected disabled>Filtrer</option>
                                            <option [ngValue]="3">3 elements par page</option>
                                            <option [ngValue]="5">5 elements par page</option>
                                            <option [ngValue]="10">10 elements par page</option>
                                        </select>
                                    </div>
                                </div>
                                <!--end::Table-->
                                </div>
                            </div>
                        </div>
                       





                        <ng-template #edit let-modal>
                            <form #frmrequeteusagerU="ngForm" (ngSubmit)="saveRequeteusager(frmrequeteusagerU.value)" class="form-horizontal " novalidate="">
                        
                                <div class="modal-header bg-info">
                                    <h5 class="modal-title text-white" id="modal-basic-title">Modifier une Préoccupation</h5>
                                    <button type="button" class="close text-white" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                        X
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div style="color:red">{{errormessage}}</div>
                        
                                    <fieldset class="divusager" style="border:1px solid #ddd;padding:10px;">
                                        <h4 style="font-size:14px;font-weight: bold;border-bottom:1px solid #eee;padding-bottom: 10px;">Point focal communal : {{user?.agent_user?.nomprenoms}} / {{user?.email}}</h4> 
                                    </fieldset>
                        
                                    <fieldset class="divusager" style="border:1px solid #ddd;padding:10px;">
                        
                                        <h4 style="font-size:14px;font-weight: bold;border-bottom:1px solid #eee;padding-bottom: 10px;">Préoccupation <small *ngIf="selected_data_req.visible==0">(Non transmise)</small> </h4>
                                        <div class="form-group row">
                                            <label for="contactUs" class="col-sm-2 control-label">Contact :</label>
                                            <div class="col-sm-4">
                                                <input type="text" class="form-control form-control-sm has-error" id="contactUs2"
                                                    name="contactUs2" placeholder="" ngModel required value="{{selected_data_req.contact}}" [(ngModel)]="selected_data_req.contact">
                                            </div>
                                            <label for="mailUs" class="col-sm-2 control-label">E-mail :</label>
                                            <div class="col-sm-4">
                                                <input type="text" class="form-control form-control-sm has-error" id="mailUs2"
                                                    name="mailUs2" placeholder="" ngModel required  value="{{selected_data_req.email}}" [(ngModel)]="selected_data_req.email">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="idEntite" class="col-sm-2">Structure destinatrice</label>
                                            <div class="col-sm-10">
                                                <select name="idEntite2" id="idEntite2" class="form-control form-control-sm"
                                                    [(ngModel)]="selected_data_req.idEntite" (change)="onEntiteChange($event)">
                                                    <option [value]="fc.id" *ngFor="let fc of institutions">{{fc.libelle}}</option>
                                                </select>
                                            </div>
                                        </div> 
                                        <div class="form-group row">
                                            <label for="plainte" class="col-sm-2 control-label">Type :</label>
                                            <div class="col-sm-4">
                                                <select name="plainte2" id="plainte2" class="form-control form-control-sm"
                                                    required [(ngModel)]="selected_type_preoccupation">
                                                    <option disabled selected>Choisir</option>
                                                    <!-- <option value="0">Requete</option> -->
                                                    <option value="1">Plainte</option>
                                                    <option value="2">Demande d'information</option>
                                                </select>
                                            </div>
                                        </div>
                                        
                                        <div class="form-group row" *ngIf="selected_type_preoccupation!=0">
                                            <label for="plainte" class="col-sm-6 control-label">Préoccupation liée à une prestation  :</label>
                                            <div class="col-sm-2">
                                            <label for=""> Oui  <input type="radio" value="1"  [(ngModel)]="link_to_prestation" name="link_to_prestation2"> </label>
                                            </div>
                                            <div class="col-sm-2">
                                                <label for=""> Non <input type="radio" value="0"  [(ngModel)]="link_to_prestation" name="link_to_prestation2"> </label>
                                            </div>
                                        </div>
                                        
                                        <div class="form-group row"  *ngIf="link_to_prestation==1 || selected_type_preoccupation==0">
                                            
                                            <label for="idType" class="col-sm-2 control-label">Thématique :</label>
                                            <div class="col-sm-4">
                                                <select name="idType2" id="idType2" required class="form-control form-control-sm" required
                                                [(ngModel)]="selected_data_req.service.idType" (change)="chargerPrestation($event)">
                                                    <option disabled selected>Choisir</option>
                                                    <option [value]="fc.id" *ngFor="let fc of themes">{{fc.libelle}}</option>
                                                </select>
                                            </div>

                                            <label for="objet" class="col-sm-2 control-label" *ngIf="mat_aff">Matricule : </label>
                                            <div class="col-sm-4" *ngIf="mat_aff">
                                                <input type="text" class="form-control form-control-sm has-error" [(ngModel)]="selected_data_req.matricule" id="matricul" name="matricul" ngModel>
                                            </div>
                                            <!-- <label for="objet" class="col-sm-2 control-label">Description : </label>
                                            <div class="col-sm-4">
                                                <textarea type="text" disabled="disabled" class="form-control" id="descr2"   name="descr2" placeholder="Description de la thématique"
                                                         ngModel  value="{{descrCarr}}"  [(ngModel)]="selected_data_req.service.type.descr"></textarea>
                                            </div> -->
                                        </div>
                                        <div class="form-group row"  *ngIf="link_to_prestation==1 || selected_type_preoccupation==0">
                                            <label for="idPrestation" required class="col-sm-2 control-label">Prestation :</label>
                                            <div class="col-sm-10">
                                                <select name="idPrestation2" id="idPrestation2" class="form-control form-control-sm form-control-sm"
                                                [(ngModel)]="selected_data_req.service==null?'':selected_data_req.service.id" (change)="openDetailModal($event, detailPresta)">
                                                    <option disabled selected>Choisir</option>
                                                    <option [value]="fc.id" *ngFor="let fc of services">{{fc.libelle}}</option>
                                                </select>
                                            </div>
                                        </div>
                        
                                        <div class="form-group row">
                                            <label for="objet" class="col-sm-2 control-label">Objet :</label>
                        
                                            <div class="col-sm-10">
                                                <input type="text" class="form-control form-control-sm has-error" id="objet2"
                                                    name="objet2" placeholder="" [(ngModel)]="selected_data_req.objet" required>
                                            </div>
                                        </div>
                        
                        
                                        <div class="form-group row">
                                            <label for="msgrequest" class="col-sm-2 control-label">Message :</label>
                                            <div class="col-sm-10">
                                                <textarea class="form-control form-control-sm has-error" id="msgrequest2" name="msgrequest2"
                                                    placeholder="Message de la requête" [(ngModel)]="selected_data_req.msgrequest" required></textarea>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                                <div class="modal-footer">
                                    <button type="submit" class="btn btn-warning mx-1" id="btn-save"><i class="fas fa-edit"></i>&nbsp;
                                        Enregistrer</button>
                                </div>
                            </form>
                        </ng-template>
                                            
                        
                        
                        <ng-template #note let-modal>
                            <form #frmnoter="ngForm" (ngSubmit)="noterRequete(frmnoter.value)" class="form-horizontal"
                                novalidate="">
                                <div class="modal-header bg-primary">
                                    <h5 class="modal-title text-white" id="modal-basic-title">Appreciation</h5>
                                    <button type="button" class="close text-white" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                        X
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <span class="help-inline has-error-col" style="color:green !important;">Afin de mieux vous
                                        servir, nous aimerions recueillir votre appréciation du traitement de votre
                                        requête/plainte.</span>
                                    <span class="help-inline has-error-col">{{errormessage}}</span>
                                    <br><br>
                                    <div class="form-group row">
                                        <label for="objet2" class="col-sm-4">Objet de la requête </label>
                                        <div class="col-sm-8">
                                            <input readonly="" type="text" class="form-control  form-control-sm has-error" id="objet2"
                                                name="objet" value="{{selected_data_note.objet}}" [(ngModel)]="selected_data_note.objet"
                                                required>
                        
                                        </div>
                                    </div>
                        
                                    <div class="form-group row" style="margin-top: 30px;">
                                        <label class="col-sm-8" style="margin-right:15px;">Quel est votre degré de satisfaction
                                            par rapport au délai de traitement de votre demande ? * </label>
                                        <select name="noteDelai" style="width: 100px; height: 35px;" id="noteDelai" class="form-control form-control-sm"
                                            required ngModel>
                                            <option [value]="fc" *ngFor="let fc of notes">{{fc}}</option>
                                        </select>
                                    </div>
                        
                        
                        
                                    <div class="form-group row" style="margin-top: 30px;">
                                        <label class="col-sm-8" style="margin-right:15px;">Quel est votre degré de satisfaction
                                            par rapport au résultat du traitement de votre sollicitation ? * </label>
                                        <select name="noteResultat" style="width: 100px; height: 35px;" id="noteResultat" class="form-control form-control-sm"
                                            required ngModel>
                                            <option [value]="fc" *ngFor="let fc of notes">{{fc}}</option>
                                        </select>
                                    </div>
                        
                                    <div class="form-group row" style="margin-top: 30px;">
                                        <label for="commentaireNotation" class="col-sm-4">Commentaire :</label>
                                        <div class="col-sm-8">
                                            <textarea class="form-control form-control-sm has-error" id="commentaireNotation"
                                                name="commentaireNotation" ngModel></textarea>
                                        </div>
                                        <br><br>
                                    </div>
                                    <div class="col-md-12 text-center d-flex justify-content-center" *ngIf="loading">
                                        <div class="spinner-border text-secondary" role="status">
                                            <span class="sr-only">Processus en cours...</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button *ngIf='selected_data_note.traiteOuiNon==1 && selected_data_note.noteUsager<1' type="submit"
                                        class="btn btn-primary" [disabled]="frmnoter.invalid"><i class="fas fa-save"></i>&nbsp;
                                        Enregistrer</button>
                                    <br><br>
                                </div>
                            </form>
                        </ng-template>
                    </div>
                </div>
            </ng-template>
        </li>


        <ng-template #detailPresta let-modal>
            <form #frmrdv="ngForm"  class="form-horizontal" novalidate="" *ngFor="let depi of detailpiece">
                <div class="modal-header bg-info">
                    <h4 class="modal-title text-white" id="modal-basic-title">Informations sur la prestation</h4>
                    <button type="button" class="close text-white" aria-label="Close" (click)="modal.dismiss('Cross click')">X</button>
                </div>
                <div class="modal-body">
                    <label > Prestation : </label>
                    <div class="form-group row" >
                        <label class="col-sm-10" style="padding-right:0px;" > {{ depi.libelle }} </label>
                    </div><br/>
                    <div class="form-group row" >
                        <label class="col-sm-3" > Structure : </label>
                        <div class="col-sm-9" >{{ depi.service_parent.libelle!=null ? depi.service_parent.libelle : 'Aucune structure'}}</div>
                    </div><br/>
                    <div class="form-group row" >
                        <label for="objet" class="col-sm-2">Délai : </label>
                        <div class="col-sm-10" >{{ depi.delai }}</div>
                    </div><br/>
                    <label for="objet" class="col-sm-12">Liste des pièces : </label>
                    <ul class="col-sm-12">
                    <li *ngFor="let piece of depi.listepieces ; index as y " style="margin-bottom: 10px; list-style: none;">{{ y+1}}. {{ piece.libellePiece }} </li>
                    </ul>
                </div>
            </form>
        </ng-template>

        <ng-template #addRequete let-modal>
            <form #frmrequeteusager="ngForm" (ngSubmit)="addRequeteusager(frmrequeteusager.value)" class="form-horizontal " novalidate="">
        
                <div class="modal-header bg-info">
                    <h5 class="modal-title text-white" id="modal-basic-title">Ajouter une Préoccupation</h5>
                    <button type="button" class="close text-white" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        X
                    </button>
                </div>
                <div class="modal-body">
                    <div style="color:red">{{errormessage}}</div>
        
                    <fieldset class="divusager" style="border:1px solid #ddd;padding:10px;">
                        <h4 style="font-size:14px;font-weight: bold;border-bottom:1px solid #eee;padding-bottom: 10px;">Point focal communal : {{user?.agent_user?.nomprenoms}} / {{user?.email}}</h4> 
        
                    </fieldset>
        
                    <fieldset *ngIf="selected_data_Whats != null" class="divusager" style="border:1px solid #ddd;padding:10px;">
                        <h4 style="font-size:14px;font-weight: bold;border-bottom:1px solid #eee;padding-bottom: 10px;">Discussions WhatsApp</h4> 
                        <div>N° WhatsApp : {{selected_data_Whats.numerowhatsapp}}</div>
                        <div>Discussions : {{selected_data_Whats.discussions}}</div>
                    </fieldset>
        
                    <fieldset class="divusager" style="border:1px solid #ddd;padding:10px;">
        
                        <h4 style="font-size:14px;font-weight: bold;border-bottom:1px solid #eee;padding-bottom: 10px;">Préoccupation</h4>
                        <div class="form-group row">
                            <label for="contactUs" class="col-sm-2 control-label">Contact :</label>
                            <div class="col-sm-4">
                                <input type="text" class="form-control form-control-sm has-error" id="contactUs"
                                    name="contactUs" placeholder="" ngModel required value="{{selected_data_rvMat==null ? '':selected_data_rvMat.matri_telep}}" [(ngModel)]="selected_data_rvMat==null ? '':selected_data_rvMat.matri_telep">
                            </div>
                            <label for="mailUs" class="col-sm-2 control-label">E-mail :</label>
                            <div class="col-sm-4">
                                <input type="text" class="form-control form-control-sm has-error" id="mailUs"
                                    name="mailUs" placeholder="" ngModel required>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="idEntite" class="col-sm-2">Structure destinatrice</label>
                            <div class="col-sm-10">
                                <select name="idEntite" id="idEntite" class="form-control form-control-sm"
                                    ngModel={{user?.idEntite}} [(ngModel)]="selected_data_rvMat==null ? '' : selected_data_rvMat.idEntite" (change)="onEntiteChange($event)">
                                    <option [value]="fc.id" *ngFor="let fc of institutions">{{fc.libelle}}</option>
                                </select>
                            </div>
                        </div> 
                        <div class="form-group row">
                            <label for="plainte" class="col-sm-2 control-label">Type :</label>
                            <div class="col-sm-4">
                                <select name="plainte" id="plainte" class="form-control form-control-sm"
                                    required [(ngModel)]="selected_type_preoccupation">
                                    <option disabled selected>Choisir</option>
                                    <!-- <option value="0">Requete</option>agent_user -->
                                    <option value="1">Plainte</option>
                                    <option value="2">Demande d'information</option>
                                </select>
                            </div>
                        </div>
                        
                        <div class="form-group row" *ngIf="selected_type_preoccupation!=0">
                            <label for="plainte" class="col-sm-6 control-label">Préoccupation liée à une prestation  :</label>
                            <div class="col-sm-2">
                            <label for=""> Oui  <input type="radio" value="1" [(ngModel)]="link_to_prestation" name="link_to_prestation"> </label>
                            </div>
                            <div class="col-sm-2">
                                <label for=""> Non <input type="radio" value="0" [(ngModel)]="link_to_prestation" name="link_to_prestation"> </label>
                            </div>
                        </div>
                        
                        <div class="form-group row"  *ngIf="link_to_prestation==1 || selected_type_preoccupation==0">
                            
                            <label for="idType" class="col-sm-2 control-label">Thématique :</label>
                            <div class="col-sm-4">
                                <select name="idType" id="idType" required class="form-control form-control-sm" required
                                    ngModel (change)="chargerPrestation($event)">
                                    <option disabled selected>Choisir</option>
                                    <option [value]="fc.id" *ngFor="let fc of themes">{{fc.libelle}}</option>
                                </select>
                            </div>
                        
                            <label for="objet" class="col-sm-2 control-label" *ngIf="mat_aff">Matricule : </label>
                            <div class="col-sm-4" *ngIf="mat_aff">
                                <input type="text" class="form-control form-control-sm has-error" id="matricule" name="matricule" ngModel>
                            </div>
                            <!-- <label for="objet" class="col-sm-2 control-label">Description : </label>
                            <div class="col-sm-4">
                                <textarea type="text" disabled="disabled" class="form-control" id="descr"   name="descr" placeholder="Description de la thématique" ngModel  value="{{descrCarr}}"></textarea>
                            </div> -->
                        </div>
                        <div class="form-group row"  *ngIf="link_to_prestation==1 || selected_type_preoccupation==0">
                            <label for="idPrestation" required class="col-sm-2 control-label">Prestation :</label>
                            <div class="col-sm-10">
                                <select name="idPrestation" id="idPrestation" class="form-control form-control-sm form-control-sm"
                                    ngModel (change)="openDetailModal($event, detailPresta)">
                                    <option disabled selected>Choisir</option>
                                    <option [value]="fc.id" *ngFor="let fc of services">{{fc.libelle}}</option>
                                </select>
                            </div>
                        </div>
        
                        <div class="form-group row">
                            <label for="objet" class="col-sm-2 control-label">Objet :</label>
                            <div class="col-sm-10">
                                <input type="text" class="form-control form-control-sm has-error" id="objet"
                                    name="objet" placeholder="" ngModel required [(ngModel)]="selected_data_rvMat==null ? '' : selected_data_rvMat.contenu_visite">
                            </div>
                        </div>
        
        
                        <div class="form-group row">
                            <label for="msgrequest" class="col-sm-2 control-label">Message :</label>
                            <div class="col-sm-10">
                                <textarea class="form-control form-control-sm has-error" id="msgrequest" name="msgrequest"
                                    placeholder="Message de la requête" ngModel required [(ngModel)]="selected_data_rvMat==null ? '' : selected_data_rvMat.observ_visite"></textarea>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-info mx-1" id="btn-save"><i class="fas fa-save"></i>&nbsp;
                        Enregistrer</button>
                        <button type="submit" (click)="setVisible()" class="btn btn-primary"><i class="fas fa-save"></i>&nbsp;
                            Transmettre</button>
                </div>
            </form>
        </ng-template>

        <ng-template #addAnswer let-modal>
            <form #frmReponse="ngForm" (ngSubmit)="addReponse(frmReponse.value)" class="form-horizontal " novalidate="">
        
                <div class="modal-header bg-secondary">
                    <h5 class="modal-title text-white" id="modal-basic-title">Donner une réponse à cette discussion</h5>
                    <button type="button" class="close text-white" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        X
                    </button>
                </div>
                <div class="modal-body">
                    <div style="color:red">{{errormessage}}</div>
        
                    <fieldset class="divusager" style="border:1px solid #ddd;padding:10px;">
                        <h4 style="font-size:14px;font-weight: bold;border-bottom:1px solid #eee;padding-bottom: 10px;">Point focal communal : {{user?.agent_user?.nomprenoms}} / {{user?.email}}</h4> 
                    </fieldset>
        
                    <fieldset *ngIf="selected_data_Whats != null" class="divusager" style="border:1px solid #ddd;padding:10px;">
                        <h4 style="font-size:14px;font-weight: bold;border-bottom:1px solid #eee;padding-bottom: 10px;">Discussions WhatsApp</h4> 
                        <div>N° WhatsApp : {{selected_data_Whats.numerowhatsapp}}</div>
                        <div>Discussions : {{selected_data_Whats.discussions}}</div>
                    </fieldset>
        
                    <fieldset class="divusager" style="border:1px solid #ddd;padding:10px;">
                        <h4 style="font-size:14px;font-weight: bold;border-bottom:1px solid #eee;padding-bottom: 10px;">Projet de réponse </h4>
                        <div class="form-group row">
                            <label for="objet" class="col-sm-3 control-label">Votre réponse : (*)</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm has-error" id="reponse_whats" name="reponse_whats" placeholder="" ngModel required>
                            </div>
                        </div>
                        <div class="row form-group">
                            <label for="message" class="col-md-3 ">Pièce Jointe</label>
                            <div class="col-md-9">
                              <input type="file" class="form-control" (change)="onFileChange($event)" #file="ngModel" name="fichier" ngModel>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-secondary mx-1" id="btn-save"><i class="fas fa-save"></i>&nbsp;Répondre</button>
                </div>
            </form>
        </ng-template>

        <li [ngbNavItem]="2">
            <a ngbNavLink>Régistre des visiteurs</a>
            <ng-template ngbNavContent class="bg-white">
                <div class="row m-3">
                    <div class="col-md-12 mx-auto">
                        <p class="text-center"> <strong>Enregistrement des visites</strong> </p>
                        <div class="col-md-12 ml-2 mr-2">
                            <div class="card my-2">
                                <div class="card-header border-0">
                                    <div class="card-toolbar d-flex justify-content-between mt-4">
                                        <ul class="nav nav-pills ml-2 nav-pills-sm nav-dark-75">
                                            <li class="nav-item">
                                                <span class="mr-2">{{dataReqrv?.length}} élement(s) </span>
                                            </li>
                                            <li class="nav-item mr-2">
                                                <form>
                                                    <div class="form-group form-inline">
                                                        <input class="form-control form-control-sm form-control-sm-sm "
                                                            placeholder="Rechercher...." type="text" [(ngModel)]="searchTextReqrv"
                                                            name="searchTextReqrv" (keyup)="searchReqrv()" />
                                                    </div>
                                                </form>
                                            </li>
                                            <li class="nav-item">
                                                <span class="mr-2">Etat des visites satisfait : </span>
                                            </li>
                                            <li class="nav-item">
                                                <div class="form-group form-inline mr-2">
                                                    <div>
                                                        <select class="form-control form-control-sm form-control-sm-sm " name="selected_Status" (change)="searchReqrv()" [(ngModel)]="selected_satist" >
                                                            <option value="">Tout</option>
                                                            <option value="oui">Oui</option>
                                                            <option value="non">Non</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </li>
                                            <li class="nav-item">
                                                <span class="mr-2">Les dates non clôturées : </span>
                                            </li>
                                            <li class="nav-item">
                                                <div class="form-group form-inline">
                                                    <div class="col-sm-6">
                                                        <select name="jourclotu" id="jourclotu" [(ngModel)]="dateACloture" class="form-control form-control-sm" (change)="searchReqrv()" required>
                                                            <option value="" selected>Sélectionner une date </option>
                                                            <option [value]="le" *ngFor="let le of nbreDay">{{le | date:'dd/MM/yyy'}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                        <div>
                                            <button *ngIf="nbreDay.length != 0" (click)="openEditModalCloture(addCloture)"  class=" mx-1 btn btn-sm btn-danger">Clôturer</button>
                                            <button (click)="openEditModalVisi(addrv)"  class=" mx-1 btn btn-sm btn-info">Ajouter une visite </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body pt-3 pb-0">
                                    <!--begin::Table-->
                                    <div class="table-responsive">
                                       <p> <strong> Liste des visites non clôturées</strong> </p>
                                    <table class="table table-bordered table-striped datatable  table-head-custom table-vertical-center"
                                        id="kt_datatable">
                                        <thead>
                                            <tr>
                                                <td class="left text-left" colspan="10">
                        
                                                    <button *ngIf="show_actionsrv" (click)="openEditModalrv(editrv)" title="Modifier la visite" class="btn mx-1 btn-xs btn-warning edit">Modifier
                                                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil"
                                                            fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                                        </svg>
                                                    </button>
                                                    <button *ngIf="show_actionsrv" title="Supprimer la visite" (click)="dropRequeteusagerrv()" class="btn mx-1 btn-xs btn-danger edit"> Supprimer
                                                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash"
                                                            fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                        </svg>
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th></th>
                                                <th width="7%">Date Enreg.</th>
                                                <th width="15%">Nature visite</th>
                                                <th width="15%">Matricule / Téléphone</th>
                                                <th width="10%">Nom et prénom(s)</th>
                                                <th width="5%">sex</th>
                                                <th width="5%">Sigle Ministère</th>
                                                <th width="5%">Préoccupation</th>
                                                <th width="15%">Satisfait (Oui / Non)</th>
                                                <th width="15%">Motif</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let elrv of dataReqrv | slice: (pagerv-1) * pageSizerv : (pagerv-1) * pageSizerv + pageSizerv; index as i">
                                                <td>
                                                    <span *ngIf="elrv.requete == null ">
                                                        <input type="radio" [value]="elrv" ngModel name="getrv" (change)="checkedReqrv($event,elrv)">
                                                    </span>
                                                </td>
                                                <td>{{elrv.created_at | date:'dd/MM/yyy'}} </td>
                                                <td>
                                                    <span *ngIf="elrv.plainte==0">Requête</span>
                                                    <span *ngIf="elrv.plainte==1">Plainte</span>
                                                    <span *ngIf="elrv.plainte==2">Demande d'information</span>
                                                </td>
                                                <td>{{elrv.matri_telep}} </td>
                                                <td>{{elrv.nom_prenom}} </td>
                                                <td>{{elrv.sex}} </td>
                                                <td>{{elrv.entite==null ? '--' : elrv.entite.sigle}} </td>
                                                <td>{{elrv.contenu_visite}} </td>
                                                <td class="text-center">
                                                    <span  class="badge bg-success text-white" *ngIf="elrv.satisfait=='oui'">Oui</span>
                                                    <span class="badge bg-success text-white" *ngIf="elrv.satisfait=='non'">Non</span>
                                                    <button  *ngIf="elrv.satisfait=='non' && elrv.requete ==null" (click)="openEditModalAddRv(addRequete)"  (click)="checkedRv_Mat($event,elrv)" class="mx-1 btn btn-sm btn-info">Transferer vers mataccueil</button>
                                                    <span *ngIf="elrv.satisfait=='non' && elrv.requete != null && elrv.requete.visible==0 && elrv.requete.reponseStructure == ''"> <br>1. En attente de transmission</span>
                                                    <span *ngIf="elrv.satisfait=='non' && elrv.requete != null && elrv.requete.visible==1  && elrv.requete.reponseStructure == ''"> <br>1. En cours de traitement au {{elrv.requete.entite==null ? '--' : elrv.requete.entite.sigle}}</span>
                                                    <span *ngIf="elrv.requete != null && elrv.requete.reponseStructure != '' "><br>Réponse : {{elrv.requete.reponseStructure}} <span *ngIf="elrv.requete.fichier_joint">Fichier réponse : <a target="_blank" (click)="ChangerFile(elrv.requete.fichier_joint)" href="#">{{elrv.requete.fichier_joint}}</a></span></span>
                                                </td> 
                                                <td>{{elrv.observ_visite}} </td>
                                            </tr>
                                            <tr *ngIf="dataReqrv?.length==0">
                                                <td colspan="10" class="text-center bg-gray-100">Aucun élément</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="d-flex justify-content-between p-2">
                                        <ngb-pagination class="" [collectionSizerv]="collectionSizerv" [(pagerv)]="pagerv"
                                            [maxSize]="5" [pageSizerv]="pageSizerv" [rotate]="true" [ellipses]="false"
                                            [boundaryLinks]="true">
                                        </ngb-pagination>

                                        <select class="custom-select custom-select-sm float-right" style="width: auto"
                                            [(ngModel)]="pageSizerv">
                                            <option selected disabled>Filtrer</option>
                                            <option [ngValue]="3">3 elements par page</option>
                                            <option [ngValue]="5">5 elements par page</option>
                                            <option [ngValue]="10">10 elements par page</option>
                                            <option [ngValue]="100">100 elements par page</option>
                                        </select>
                                    </div>
                                </div>
                                <!--end::Table-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </ng-template>
            <ng-template #addrv let-modal>
                <form #frmrequeteusager="ngForm" (ngSubmit)="addRequeteRv(frmrequeteusager.value)" class="form-horizontal " novalidate="">
            
                    <div class="modal-header bg-info">
                        <h5 class="modal-title text-white" id="modal-basic-title">Ajouter une visite à la date {{dateACloture == "" ? ' de ce jour' : dateACloture | date:'dd/MM/yyy' }}</h5>
                        <button type="button" class="close text-white" aria-label="Close" (click)="modal.dismiss('Cross click')">
                            X
                        </button>
                    </div>
                    <div class="modal-body">
                        <div style="color:red">{{errormessage}}</div>
            
            
            
                        <fieldset class="divusager" style="border:1px solid #ddd;padding:10px;">
                            
                            <h4 style="font-size:14px;font-weight: bold;border-bottom:1px solid #eee;padding-bottom: 10px;">Préoccupation</h4>
                            <div class="form-group row">
                                <label for="contactMatri" class="col-sm-2 control-label">Matricule / Contact </label>
                                <div class="col-sm-4">
                                    <input type="text" class="form-control form-control-sm has-error" id="contactMatri"
                                        name="contactMatri" placeholder="" ngModel required>
                                </div>
                                <label for="plainterv" class="col-sm-2 control-label">Type :</label>
                                <div class="col-sm-4">
                                    <select name="plainterv" id="plainterv" class="form-control form-control-sm" required ngModel>
                                        <option disabled selected>Choisir</option>
                                        <option value="0">Requête</option>
                                        <option value="1">Plainte</option>
                                        <option value="2">Demande d'information</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="nom_pre_rv" class="col-sm-2 control-label">Nom & prénom (s) :</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control form-control-sm has-error" id="nom_pre_rv"
                                        name="nom_pre_rv" placeholder="" ngModel required>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="sex_pre_rv" class="col-sm-2 control-label">Sexe</label>
                                <div class="col-sm-10">
                                 <select name="sex" ngModel required class="form-control form-control-sm has-error">
                                    <option value="Homme">Homme</option>
                                    <option value="Femme">Femme</option>
                                 </select>  
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="idEntite" class="col-sm-2">Structure destinatrice</label>
                                <div class="col-sm-10">
                                    <select name="idEntite" id="idEntite" class="form-control form-control-sm"
                                        ngModel={{user?.idEntite}}>
                                        <option [value]="fc.id" *ngFor="let fc of institutions">{{fc.libelle}}</option>
                                    </select>
                                </div>
                            </div> 
                            <div class="form-group row">
                                <label for="preoccurv" class="col-sm-2 control-label">Préoccupation :</label>
            
                                <div class="col-sm-10">
                                    <input type="text" class="form-control form-control-sm has-error" id="preoccurv"
                                        name="preoccurv" placeholder="" ngModel required>
                                </div>
                            </div>

                            <h4 style="font-size:14px;font-weight: bold;border-bottom:1px solid #eee;padding-bottom: 10px;">Appréciation</h4>

                            <div class="form-group row">
                                <label for="satisfaitrv" class="col-sm-2 control-label">Satisfait ? </label>
                                <div class="col-sm-4">
                                    <select name="satisfaitrv" id="satisfaitrv" class="form-control form-control-sm" required ngModel>
                                        <option value="" selected>Choisir</option>
                                        <option value="oui">Oui</option>
                                        <option value="non">Non</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="observarv" class="col-sm-2 control-label">Observation :</label>
                                <div class="col-sm-10">
                                    <textarea class="form-control form-control-sm has-error" id="observarv" name="observarv"
                                        placeholder="Noter les observations" ngModel required></textarea>
                                </div>
                            </div>
                            
                        </fieldset>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-info mx-1" id="btn-saverv"><i class="fas fa-save"></i>&nbsp;
                            Enregistrer</button>
                    </div>
                </form>
            </ng-template>
            
            <ng-template #addCloture let-modal>
                <form #frmrequeteusager="ngForm" (ngSubmit)="addClotureRv(frmrequeteusager.value)" class="form-horizontal " novalidate="">
            
                    <div class="modal-header bg-info">
                        <h5 class="modal-title text-white" id="modal-basic-title">Clôturer les régistres du {{dateACloture | date:'dd/MM/yyy' }} </h5>
                        <button type="button" class="close text-white" aria-label="Close" (click)="modal.dismiss('Cross click')">X </button>
                    </div>
                    <div class="modal-body">
                        <div style="color:red">{{errormessage}}</div>
                        <fieldset class="divusager" style="border:1px solid #ddd;padding:10px;">
                            <h4 style="font-size:14px;font-weight: bold;border-bottom:1px solid #eee;padding-bottom: 10px;">Renseignez le nombre de visite sur votre régistre physique</h4>
                            <div class="form-group row">
                                <label for="nbrevisite" class="col-sm-6 control-label">Nombre de visite :</label>
                                <div class="col-sm-6">
                                    <input type="number" class="form-control form-control-sm has-error" id="nbrevisite"
                                        name="nbrevisite" placeholder="" ngModel required>
                                </div>
                            </div>
                            <h4 style="font-size:14px;font-weight: bold;border-bottom:1px solid #eee;padding-bottom: 10px;">Prendre une photo de votre régistre physique et joindre</h4>
                            <div class="row form-group">
                                <label for="message" class="col-md-2 ">Photo Jointe</label>
                                <div class="col-md-10">
                                  <input type="file" class="form-control" (change)="onFileChange($event)" #file="ngModel" name="fichier" ngModel>
                                </div>
                              </div>
                            
                        </fieldset>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-danger mx-1" id="btn-saverv"><i class="fas fa-save"></i>&nbsp;Clôturer</button>
                    </div>
                </form>
            </ng-template>
            
            <ng-template #editrv let-modal>
                <form #frmrequeteusager="ngForm" (ngSubmit)="UpdateRequeteRv(frmrequeteusager.value)" class="form-horizontal"
                    novalidate="">

                    <div class="modal-header bg-info">
                        <h5 class="modal-title text-white" id="modal-basic-title">Modifier une visite</h5>
                        <button type="button" class="close text-white" aria-label="Close" (click)="modal.dismiss('Cross click')">
                            X
                        </button>
                    </div>
                    <div class="modal-body">
                        <div style="color:red">{{errormessage}}</div>
            
                        <fieldset class="divusager" style="border:1px solid #ddd;padding:10px;">
                            
                            <h4 style="font-size:14px;font-weight: bold;border-bottom:1px solid #eee;padding-bottom: 10px;">Préoccupation</h4>
                            <div class="form-group row">
                                <label for="contactMatri" class="col-sm-2 control-label">Matricule / Contact </label>
                                <div class="col-sm-4">
                                    <input type="text" class="form-control form-control-sm has-error" id="contactMatri"
                                        name="contactMatri" placeholder="" ngModel required value="{{selected_data_reqrv.matri_telep}}" [(ngModel)]="selected_data_reqrv.matri_telep">
                                </div>
                                <label for="plainterv" class="col-sm-2 control-label">Type :</label>
                                <div class="col-sm-4">
                                    <select name="plainterv" id="plainterv" class="form-control form-control-sm" required [(ngModel)]="selected_data_reqrv.plainte">
                                        <option disabled selected>Choisir</option>
                                        <option value="0">Requête</option>
                                        <option value="1">Plainte</option>
                                        <option value="2">Demande d'information</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="nom_pre_rv" class="col-sm-2 control-label">Nom & prénom (s) :</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control form-control-sm has-error" id="nom_pre_rv"
                                        name="nom_pre_rv" placeholder="" ngModel required value="{{selected_data_reqrv.nom_prenom}}"  [(ngModel)]="selected_data_reqrv.nom_prenom">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="sex_pre_rv" class="col-sm-2 control-label">Sexe</label>
                                <div class="col-sm-10">
                                 <select name="sex" [(ngModel)]="selected_data_reqrv.sex" required class="form-control form-control-sm has-error">
                                    <option value="Homme">Homme</option>
                                    <option value="Femme">Femme</option>
                                 </select>  
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="idEntite" class="col-sm-2">Structure destinatrice</label>
                                <div class="col-sm-10">
                                    <select name="idEntite" id="idEntite" class="form-control form-control-sm"
                                        [(ngModel)]="selected_data_reqrv.idEntite">
                                        <option [value]="fc.id" *ngFor="let fc of institutions">{{fc.libelle}}</option>
                                    </select>
                                </div>
                            </div> 
                            <div class="form-group row">
                                <label for="preoccurv" class="col-sm-2 control-label">Préoccupation :</label>
            
                                <div class="col-sm-10">
                                    <input type="text" class="form-control form-control-sm has-error" id="preoccurv"
                                        name="preoccurv" placeholder="" ngModel required value="{{selected_data_reqrv.contenu_visite}}"  [(ngModel)]="selected_data_reqrv.contenu_visite">
                                </div>
                            </div>

                            <h4 style="font-size:14px;font-weight: bold;border-bottom:1px solid #eee;padding-bottom: 10px;">Appréciation</h4>

                            <div class="form-group row">
                                <label for="satisfaitrv" class="col-sm-2 control-label">Satisfait ? </label>
                                <div class="col-sm-4">
                                    <select name="satisfaitrv" id="satisfaitrv" class="form-control form-control-sm" required ngModel [(ngModel)]="selected_data_reqrv.satisfait">
                                        <option value="" selected>Choisir</option>
                                        <option value="oui">Oui</option>
                                        <option value="non">Non</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="observarv" class="col-sm-2 control-label">Observation :</label>
                                <div class="col-sm-10">
                                    <textarea class="form-control form-control-sm has-error" id="observarv" name="observarv"
                                        placeholder="Noter les observations" ngModel required value="{{selected_data_reqrv.observ_visite}}"  [(ngModel)]="selected_data_reqrv.observ_visite"></textarea>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div class="modal-footer">

                        <button type="submit" class="btn btn-warning mx-1"><i class="bi bi-pencil"></i>&nbsp;
                            Modifier</button>
                    </div>
                </form>

            </ng-template>
        </li>

        <li [ngbNavItem]="3" *ngIf="onglet_What==true">
            <a ngbNavLink>Échange WhatsApp</a>
            <ng-template ngbNavContent class="bg-white">
                <div class="row m-3">
                    <div class="col-md-12 mx-auto">
                        <p class="text-center"> <strong>Enregistrement des discussions WhatsApp</strong> </p>
                        <div class="col-md-12 ml-2 mr-2">
                            <div class="card my-2">
                                <div class="card-header border-0">
                                    <div class="card-toolbar d-flex justify-content-between mt-4">
                                        <ul class="nav nav-pills ml-2 nav-pills-sm nav-dark-75">
                                            <li class="nav-item">
                                                <span class="mr-2">{{dataWhats?.length}} élement(s) </span>
                                            </li>
                                            <li class="nav-item mr-2">
                                                <form>
                                                    <div class="form-group form-inline">
                                                        <input class="form-control form-control-sm form-control-sm-sm "
                                                            placeholder="Rechercher...." type="text" [(ngModel)]="searchTextWhats"
                                                            name="searchTextWhats" (keyup)="searchWhats()" />
                                                    </div>
                                                </form>
                                            </li>
                                            <li class="nav-item">
                                                <span class="mr-2">Statut : </span>
                                            </li>
                                            <li class="nav-item">
                                                <div class="form-group form-inline mr-2">
                                                    <div>
                                                        <select class="form-control form-control-sm form-control-sm-sm " name="selected_Status" (change)="loadWhatsApp()" [(ngModel)]="selected_traiteWha" >
                                                            <option value="non" selected>Non traité</option>
                                                            <option value="oui">traité</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </li>
                                            
                                        </ul>
                                        <div>
                                            <button (click)="openEditModalWhatsApp(addWhats)"  class=" mx-1 btn btn-sm btn-info">Ajouter une discussion </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body pt-3 pb-0">
                                    <!--begin::Table-->
                                    <div class="table-responsive">
                                       <p> <strong> Liste des discussions </strong> </p>
                                    <table class="table table-bordered table-striped datatable  table-head-custom table-vertical-center" id="kt_datatable">
                                        <thead>
                                            <tr>
                                                <td class="left text-left" colspan="10">
                        
                                                    <button *ngIf="show_actionsWhats" (click)="openEditModalWhats(editWhats)" title="Modifier la discussion" class="btn mx-1 btn-xs btn-warning edit">Modifier
                                                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                                        </svg>
                                                    </button>
                                                                       
                                                    <button *ngIf="show_actionsWhats" title="Supprimer à la discussion" (click)="dropDiscussionWhat()" class="btn mx-1 btn-xs btn-danger edit"> Supprimer
                                                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash"
                                                            fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                        </svg>
                                                    </button>

                                                </td>
                                            </tr>
                                            <tr>
                                                <th width="3%"></th>
                                                <th width="10%" class="text-center">Date Enreg.</th>
                                                <th width="10%" class="text-center">Numéro WhatsApp</th>
                                                <th width="22%">Discussions</th>
                                                <th width="15%" class="text-center">Initiateur</th>
                                                <th width="15%" class="text-center">Ajouter dans mataccueil par</th>
                                                <th width="10%" class="text-center">Statut</th>
                                                <th width="15%" class="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let elwh of dataWhats | slice: (pagerv-1) * pageSizerv : (pagerv-1) * pageSizerv + pageSizerv; index as i">
                                                <td>
                                                    <span *ngIf="elwh.requete == null && elwh.reponse_agent == null">
                                                        <input type="radio" [value]="elwh" ngModel name="getrv" (change)="checkedWhatsap($event,elwh)">
                                                    </span>
                                                </td>
                                                <td>{{elwh.created_at | date:'dd/MM/yyy'}} </td>
                                                <td>{{elwh.numerowhatsapp}}</td>
                                                <td>{{elwh.discussions}} </td>
                                                <td class="text-center"> {{elwh.creator.agent_user?.nomprenoms}}</td>
                                                <td class="text-center"> 
                                                    <span *ngIf="elwh.requete == null && elwh.user_trait==null">--</span>
                                                    <span *ngIf="elwh.requete == null && elwh.user_trait!=null">{{elwh.user_trait==null ? '--' : 'En cours par '+elwh.user_trait.agent_user?.nomprenoms}}</span>
                                                    <span *ngIf="elwh.requete != null && elwh.user_trait!=null">{{elwh.user_trait==null ? '--' : elwh.user_trait.agent_user?.nomprenoms}}</span>
                                                    <span *ngIf="elwh.requete != null && elwh.requete.visible==1  && elwh.requete.reponseStructure == ''">{{elwh.user_trait==null ? '--' : elwh.user_trait.agent_user?.nomprenoms}}</span>
                                                </td>
                                                <td class="text-center">
                                                    <span  class="badge badge-success" *ngIf="elwh.traite_disc=='oui' && elwh.requete != null && elwh.requete.reponseStructure != null">Traité</span>
                                                    <span  class="badge badge-success" *ngIf="elwh.traite_disc=='oui' && elwh.requete == null && elwh.reponse_agent != null">Traité</span>
                                                    <span  class="badge badge-info" *ngIf="elwh.traite_disc=='oui' && elwh.requete != null && elwh.requete.reponseStructure == null">En cours</span>
                                                    <span class="badge badge-danger" *ngIf="elwh.traite_disc=='non'">Non Traité</span>
                                                </td>
                                                <td>
                                                    <button  *ngIf="elwh.traite_disc=='non' && elwh.user_trait == null" (click)="ConfirmerTraitement(elwh)" class="mx-1 btn btn-sm btn-success">Confirmer le traitement </button>
                                                    <span *ngIf="elwh.traite_disc=='non' && elwh.user_trait != null && elwh.id_userTraite == user?.id">
                                                        <button title="Transferer vers mataccueil" (click)="openEditModalAdd(addRequete)"  (click)="checkedWhatsap($event,elwh)" class="mx-1 btn btn-sm btn-info">Transférer </button>
                                                         ou 
                                                        <button  title="Donner une réponse" (click)="openEditModalAdd(addAnswer)"  (click)="checkedWhatsap($event,elwh)" class="mx-1 btn btn-sm btn-secondary">Répondre</button>
                                                    </span>
                                                    <span *ngIf="elwh.traite_disc=='non' && elwh.requete != null && elwh.requete.visible==0 && elwh.requete.reponseStructure == ''"> <br>1. En attente de transmission</span>
                                                    <span *ngIf="elwh.traite_disc=='non' && elwh.requete != null && elwh.requete.visible==1  && elwh.requete.reponseStructure == ''"> <br>1. En cours de traitement au {{elwh.requete.entite==null ? '--' : elwh.requete.entite.sigle}}</span>
                                                    <span *ngIf="elwh.requete != null && elwh.requete.reponseStructure != null "><br>Réponse : {{elwh.requete.reponseStructure}} <span *ngIf="elwh.requete.fichier_joint">Fichier réponse : <a target="_blank" (click)="ChangerFile(elwh.requete.fichier_joint)" href="#">{{elwh.requete.fichier_joint}}</a></span></span>
                                                    <span *ngIf="elwh.requete == null && elwh.traite_disc=='oui' "><br>Réponse : {{elwh.reponse_agent}} <span *ngIf="elwh.fichier_joint">Fichier réponse : <a target="_blank" (click)="ChangerFile(elwh.fichier_joint)" href="#">{{elwh.fichier_joint}}</a></span></span>
                                                </td>
                                            </tr>
                                            <tr *ngIf="dataWhats?.length==0">
                                                <td colspan="10" class="text-center bg-gray-100">Aucun élément</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="d-flex justify-content-between p-2">
                                        <ngb-pagination class="" [collectionSizerv]="collectionSizerv" [(pagerv)]="pagerv"
                                            [maxSize]="5" [pageSizerv]="pageSizerv" [rotate]="true" [ellipses]="false"
                                            [boundaryLinks]="true">
                                        </ngb-pagination>

                                        <select class="custom-select custom-select-sm float-right" style="width: auto"
                                            [(ngModel)]="pageSizerv">
                                            <option selected disabled>Filtrer</option>
                                            <option [ngValue]="3">3 elements par page</option>
                                            <option [ngValue]="5">5 elements par page</option>
                                            <option [ngValue]="10">10 elements par page</option>
                                            <option [ngValue]="100">100 elements par page</option>
                                        </select>
                                    </div>
                                </div>
                                <!--end::Table-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </ng-template>
            <ng-template #addWhats let-modal>
                <form #frmrequeteusager="ngForm" (ngSubmit)="addWhatsApp(frmrequeteusager.value)" class="form-horizontal " novalidate="">
            
                    <div class="modal-header bg-info">
                        <h5 class="modal-title text-white" id="modal-basic-title">Ajouter une discussion</h5>
                        <button type="button" class="close text-white" aria-label="Close" (click)="modal.dismiss('Cross click')">
                            X
                        </button>
                    </div>
                    <div class="modal-body">
                        <div style="color:red">{{errormessage}}</div>
                        <fieldset class="divusager" style="border:1px solid #ddd;padding:10px;">
                            
                            <h4 style="font-size:14px;font-weight: bold;border-bottom:1px solid #eee;padding-bottom: 10px;">Information sur la discussion</h4>
                            <div class="form-group row">
                                <label for="contWhatsapp" class="col-sm-2 control-label">N° WhatsApp </label>
                                <div class="col-sm-4">
                                    <input type="text" class="form-control form-control-sm has-error" id="contWhatsapp"
                                        name="contWhatsapp" placeholder="" ngModel required>
                                </div>
                            </div>
                            <h4 style="font-size:14px;font-weight: bold;border-bottom:1px solid #eee;padding-bottom: 10px;">Détails de la conversation</h4>
                            <div class="form-group row">
                                <label for="discussiontxt" class="col-sm-2 control-label">Discussions :</label>
                                <div class="col-sm-10">
                                    <textarea class="form-control form-control-sm has-error" rows="10" id="discussiontxt" name="discussiontxt"
                                        placeholder="Noter seulement les détails important de la discussion : E-mail / Type de requête / Ministère / Objet / Thématique / Autres..." ngModel required></textarea>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-info mx-1" id="btn-saverv"><i class="fas fa-save"></i>&nbsp;
                            Enregistrer</button>
                    </div>
                </form>
            </ng-template>
            
            <ng-template #editWhats let-modal>
                <form #frmrequeteusager="ngForm" (ngSubmit)="UpdateRequeteWhats(frmrequeteusager.value)" class="form-horizontal"
                    novalidate="">

                    <div class="modal-header bg-info">
                        <h5 class="modal-title text-white" id="modal-basic-title">Modifier une discussion</h5>
                        <button type="button" class="close text-white" aria-label="Close" (click)="modal.dismiss('Cross click')">
                            X
                        </button>
                    </div>
                    <div class="modal-body">
                        <div style="color:red">{{errormessage}}</div>
            
                        <fieldset class="divusager" style="border:1px solid #ddd;padding:10px;">
                            <h4 style="font-size:14px;font-weight: bold;border-bottom:1px solid #eee;padding-bottom: 10px;">Information sur la discussion</h4>
                            <div class="form-group row">
                                <label for="contWhatsapp" class="col-sm-2 control-label">N° WhatsApp </label>
                                <div class="col-sm-4">
                                    <input type="text" class="form-control form-control-sm has-error" id="contWhatsapp"
                                        name="contWhatsapp" value="{{selected_data_Whats.numerowhatsapp}}" ngModel  [(ngModel)]="selected_data_Whats.numerowhatsapp" required>
                                </div>
                            </div>
                            <h4 style="font-size:14px;font-weight: bold;border-bottom:1px solid #eee;padding-bottom: 10px;">Détails de la conversation</h4>
                            <div class="form-group row">
                                <label for="discussiontxt" class="col-sm-2 control-label">Discussions :</label>
                                <div class="col-sm-10">
                                    <textarea class="form-control form-control-sm has-error" rows="10" id="discussiontxt" name="discussiontxt"
                                        placeholder="Noter seulement les détails important de la discussion : E-mail / Type de requête / Ministère / Objet / Thématique / Autres..." ngModel required
                                        value="{{selected_data_Whats.discussions}}"  [(ngModel)]="selected_data_Whats.discussions"></textarea>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div class="modal-footer">

                        <button type="submit" class="btn btn-warning mx-1"><i class="bi bi-pencil"></i>&nbsp;
                            Modifier</button>
                    </div>
                </form>
            </ng-template>
        </li>
       

        
        <li [ngbNavItem]="4">
            <a ngbNavLink>Statistique de visite</a>
            <ng-template ngbNavContent class="bg-white">
                <div class="container">
                    <div class="p-3">
                        <form #registreForm="ngForm" (ngSubmit)="getRegistres(registreForm.value)" class="form-horizontal " novalidate="">

                        <div class="input-group">
                            <span class="input-group-text">Filtre</span>
                            <input type="date" placeholder="Date début" aria-label="Date début" name="start_date" ngModel class="form-control" required>
                            <input type="date" placeholder="Date fin" aria-label="Date fin" name="end_date" ngModel class="form-control" required>
                            <select name="sex" class="form-control" ngModel id="">
                                <option value="Homme">Homme</option>
                                <option value="Femme">Femme</option>
                            </select>
                            <button class="btn btn-outline-secondary" [disabled]="registreForm.invalid || loading2" type="submit"  id="button-addon2">Rechercher <app-loading [isVisible]="loading2"></app-loading></button>
                          </div>
                        </form>
                    </div>
                    <p>{{registres!.length}} élément(s) <app-loading [isVisible]="loading2"></app-loading></p>
                    <div class="table-responsive">
                    <table class="table table-sm table-bordered">
                        <thead class="">
                            <tr>
                                <th>Date d'enreg</th>
                                <th>Nature de visite</th>
                                <th>Matricule/Téléphone</th>
                                <th>Nom et prénom(s)</th>
                                <th>Sexe</th>
                                <th>Sigle Ministère</th>
                                <th>Préoccupation</th>
                                <th>Satisfait Oui/Non</th>
                                <th>Motif</th>
                            </tr>
                        </thead>
                        <tbody>

                             <tr *ngFor="let rg of registres 
                             | sampleSearch:search_text:'registre' 
                             | paginate: {
                                    id:'ngxP1',
                                    itemsPerPage: pg.pageSize,
                                    currentPage: pg.p,
                                    totalItems: pg.total }">
                            <td>{{rg.created_at | date:'dd/MM/yyy'}} </td>
                            <td>
                                <span *ngIf="rg.plainte==0">Requête</span>
                                <span *ngIf="rg.plainte==1">Plainte</span>
                                <span *ngIf="rg.plainte==2">Demande d'information</span>
                            </td>
                            <td>{{rg.matri_telep}} </td>
                            <td>{{rg.nom_prenom}} </td>
                            <td>{{rg.sex}} </td>
                            <td>{{rg.entite==null ? '--' : rg.entite.sigle}} </td>
                            <td>{{rg.contenu_visite}} </td>
                            <td class="text-center">
                                <span  class="badge bg-success text-white" *ngIf="rg.satisfait=='oui'">Oui</span>
                                <span class="badge bg-success text-white" *ngIf="rg.satisfait=='non'">Non</span>
                            </td> 
                            <td>{{rg.observ_visite}} </td>
                            </tr>
                        
                        </tbody>
                    </table>

                    </div>
 <pagination-controls 
                        id="ngxP1"
                        (pageChange)="getPage($event)"
                      [maxSize]="9"
                      [directionLinks]="true"
                      [autoHide]="true"
                      [responsive]="true"
                      [previousLabel]="'Précédent'"
                      [nextLabel]="'Suivant'"
                      [screenReaderPaginationLabel]="'Pagination'"
                      [screenReaderPageLabel]="'page'"
                      [screenReaderCurrentLabel]="'Vous êtes sur la page'">
                     </pagination-controls>
                </div>
            </ng-template>
        </li>

        <li [ngbNavItem]="5">
            <a ngbNavLink>Génération de rapport mensuel de visite</a>
           
            <ng-template ngbNavContent class="bg-white">
               
                <div class="container">
                    <div class="text-end mb-3">
                        <button (click)="openReportModal(addReport)" class=" mx-1 btn btn-sm btn-info">Générer un Rapport</button>
                    </div>
                    <p>{{registresReports!.length}} élément(s) <app-loading [isVisible]="loading2"></app-loading></p>
                    <div class="table-responsive">
                    <table class="table table-sm table-bordered">
                        <thead class="">
                            <tr>
                                <th>#</th>
                                <th>Titre</th>
                                <th>Usagers reçus</th>
                                <th>Usagers satisfaits</th>
                                <th>Statut</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>

                             <tr *ngFor="let rgr of registresReports 
                             | sampleSearch:search_text:'registre' 
                             | paginate: {
                                    id:'ngxP2',
                                    itemsPerPage: pg2.pageSize,
                                    currentPage: pg2.p,
                                    totalItems: pg2.total }">
                                    <td>
                                        <input type="radio" (change)="checkedRegistreReport(rgr)">

                                    </td>
                            <td>{{rgr.title}} </td>
                          
                            <td>{{rgr.customer_recieved}} </td>
                            <td>{{rgr.customer_satisfied}} </td>
                            <td>
                                <span *ngIf="rgr.status==0">Nouveau</span>
                                <span *ngIf="rgr.status==1 &&  rgr?.transmission.user_down!=user.id">En attente de validation</span>
                                <span *ngIf="rgr.status==1 && rgr?.transmission.sens!=1 && rgr?.transmission.user_down==user.id">En attente de correction</span>
                                <span *ngIf="rgr.status==2">Validé</span>
                                <br>
                                <button *ngIf="rgr.status==1 && rgr?.transmission.sens!=1 && rgr?.transmission.user_down==user.id" class="btn btn-sm btn-primary mx-1" [disabled]="loading2" (click)="openReportShowModal(correctReport,rgr)"> voir le motif</button>

                            </td>
                            <td>
                                <button class="btn btn-sm btn-primary mx-1" [disabled]="loading2" (click)="openReportShowModal(showReport,rgr)"> <i class="fa fa-eye text-white"></i> </button>
                                <ng-container *ngIf="rgr.status==0 || (rgr.status==1 && rgr?.transmission.sens!=1 && rgr?.transmission.user_down==user.id)">
                                    <button class="btn btn-sm btn-warning mx-1" [disabled]="loading2" (click)="openReportEditModal(editReport,rgr)"> <i class="fa fa-edit text-white"></i> </button>
                                    <button class="btn btn-sm btn-danger mx-1" [disabled]="loading2" (click)="deleteReport(rgr.id)"> <i class="fa fa-trash text-white"></i> </button>
                                    <button class="btn btn-sm btn-success  mx-1" [disabled]="loading2" (click)="transmit(rgr.id)"> <i class="fa fa-arrow-up text-white"></i> </button>
                                </ng-container>
                                 <button class="btn btn-sm btn-info  mx-1" [disabled]="loading2" (click)="getFile(rgr.filename)"> <i class="fa fa-file text-white"></i> </button>
                            </td>
                            </tr>
                        
                        </tbody>
                    </table>

                    </div>
 <pagination-controls 
                        id="ngxP2"
                        (pageChange)="getPage2($event)"
                      [maxSize]="9"
                      [directionLinks]="true"
                      [autoHide]="true"
                      [responsive]="true"
                      [previousLabel]="'Précédent'"
                      [nextLabel]="'Suivant'"
                      [screenReaderPaginationLabel]="'Pagination'"
                      [screenReaderPageLabel]="'page'"
                      [screenReaderCurrentLabel]="'Vous êtes sur la page'">
                     </pagination-controls>
                </div>
            </ng-template>

          
    
        </li>
    </ul>
    <div class="">
        <button (click)="logout()" class="btn btn-sm btn-danger align-self-center">Déconnexion</button>
    </div>
</div>
<div [ngbNavOutlet]="nav" class="mt-2"></div>

<ng-template #addReport let-modal>
    <form #addReportForm="ngForm" (ngSubmit)="storeReport(addReportForm.value)" class="form-horizontal " novalidate="">

        <div class="modal-header bg-secondary">
            <h5 class="modal-title text-white" id="modal-basic-title">Générer un rapport</h5>
            <button type="button" class="close text-white" aria-label="Close" (click)="modal.dismiss('Cross click')">
                X
            </button>
        </div>
        <div class="modal-body">
            <div style="color:red">{{errormessage}}</div>

            <div class="row">
                <div class="col-12 mb-3">
                    <div class="form-group">
                        <label for="">Mois</label>
                        <select name="month" ngModel class="form-control">
                            <option value="01">Janvier</option>
                            <option value="02">Février</option>
                            <option value="03">Mars</option>
                            <option value="04">Avril</option>
                            <option value="05">Mai</option>
                            <option value="06">Juin</option>
                            <option value="07">Juillet</option>
                            <option value="08">Août</option>
                            <option value="09">Septembre</option>
                            <option value="10">Octobre</option>
                            <option value="11">Novembre</option>
                            <option value="12">Décembre</option>
                        </select>
                    </div>
                </div>
                <div class="col-12 mb-3">
                    <label for="">Sexe</label>
                    <select name="sex" ngModel  class="form-control form-control-sm has-error">
                        <option value="Homme">Homme</option>
                        <option value="Femme">Femme</option>
                     </select>  
                </div>
                <div class="col-12 mb-3">
                    <div class="form-group">
                        <label for="">Principales raisons d'insatisfaction</label>
                        <textarea name="unsatified_reason" ngModel class="form-control"></textarea>
                    </div>
                </div>
                <div class="col-12 mb-3">
                    <div class="form-group">
                        <label for="">Problèmes / difficultés rencontrées par le Point Focal </label>
                        <textarea name="difficult" ngModel class="form-control"></textarea>
                    </div>
                </div>
                <div class="col-12 mb-3">
                    <div class="form-group">
                        <label for="">Approches de solutions préconisées par le Point Focal </label>
                        <textarea name="solution" ngModel class="form-control"></textarea>
                    </div>
                </div>
                <div class="col-12 mb-3">
                    <div class="form-group">
                        <label for="">Observations</label>
                        <textarea name="observation" ngModel class="form-control"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" [disabled]="loading2"  class="btn btn-secondary mx-1" ><i class="fas fa-save"></i>&nbsp;Générer</button>
        </div>
    </form>
</ng-template>
<ng-template #editReport let-modal>
    <form #editReportForm="ngForm" (ngSubmit)="updateReport(editReportForm.value)" class="form-horizontal " novalidate="">

        <div class="modal-header bg-secondary">
            <h5 class="modal-title text-white" id="modal-basic-title">Mettre à jour le rapport</h5>
            <button type="button" class="close text-white" aria-label="Close" (click)="modal.dismiss('Cross click')">
                X
            </button>
        </div>
        <div class="modal-body">
            <div style="color:red">{{errormessage}}</div>

            <div class="row">
               
                <div class="col-12 mb-3">
                    <div class="form-group">
                        <label for="">Principales raisons d'insatisfaction</label>
                        <textarea name="unsatified_reason" [(ngModel)]="selected_data.unsatified_reason" class="form-control"></textarea>
                    </div>
                </div>
                <div class="col-12 mb-3">
                    <div class="form-group">
                        <label for="">Problèmes / difficultés rencontrées par le Point Focal </label>
                        <textarea name="difficult" [(ngModel)]="selected_data.difficult" class="form-control"></textarea>
                    </div>
                </div>
                <div class="col-12 mb-3">
                    <div class="form-group">
                        <label for="">Approches de solutions préconisées par le Point Focal </label>
                        <textarea name="solution" [(ngModel)]="selected_data.solution" class="form-control"></textarea>
                    </div>
                </div>
                <div class="col-12 mb-3">
                    <div class="form-group">
                        <label for="">Observations</label>
                        <textarea name="observation" [(ngModel)]="selected_data.observation" class="form-control"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit"  class="btn btn-secondary mx-1" ><i class="fas fa-save"></i>&nbsp;Modifier</button>
        </div>
    </form>
</ng-template>
<ng-template #showReport let-modal>
    <form #editReportForm="ngForm" (ngSubmit)="updateReport(editReportForm.value)" class="form-horizontal " novalidate="">

        <div class="modal-header bg-secondary">
            <h5 class="modal-title text-white" id="modal-basic-title">Détails rapport</h5>
            <button type="button" class="close text-white" aria-label="Close" (click)="modal.dismiss('Cross click')">
                X
            </button>
        </div>
        <div class="modal-body">
            <div style="color:red">{{errormessage}}</div>

            <div class="row">
               
                <div class="col-12 mb-3">
                    <div class="form-group">
                        <label for="">Usagers reçus</label>
                        <p>{{selected_data.customer_recieved}}</p>
                    </div>
                </div>
                <div class="col-12 mb-3">
                    <div class="form-group">
                        <label for="">Usagers satisfaits</label>
                        <p>{{selected_data.customer_satisfied}}</p>
                    </div>
                </div>
                <div class="col-12 mb-3">
                    <div class="form-group">
                        <label for="">Principales raisons d'insatisfaction</label>
                        <p>{{selected_data.unsatified_reason}}</p>
                    </div>
                </div>
                <div class="col-12 mb-3">
                    <div class="form-group">
                        <label for="">Problèmes / difficultés rencontrées par le Point Focal </label>
                        <p>{{selected_data.difficult}}</p>
                    </div>
                </div>
                <div class="col-12 mb-3">
                    <div class="form-group">
                        <label for="">Approches de solutions préconisées par le Point Focal </label>
                        {{selected_data.solution}}
                    </div>
                </div>
                <div class="col-12 mb-3">
                    <div class="form-group">
                        <label for="">Observations</label>
                        {{selected_data.observation}}
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
        </div>
    </form>
</ng-template>
<ng-template #contentPDF let-offcanvas>
    <div class="offcanvas-header">
    
        <h4 class="offcanvas-title">Visualiseur de fichier</h4>
        <button id="closePDFBtn" type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
    </div>
    <div class="offcanvas-body">
        <ngx-extended-pdf-viewer [src]="pdfSrc"
        style="width: 100%; height: 100%"
        ></ngx-extended-pdf-viewer>
    </div>
</ng-template>
<ng-template #correctReport let-modal>

    <div class="modal-header bg-secondary">
        <h5 class="modal-title text-white" id="modal-basic-title">Détails instruction</h5>
        <button type="button" class="close text-white" aria-label="Close" (click)="modal.dismiss('Cross click')">
            X
        </button>
    </div>
    <div class="modal-body">

        <div class="row">
           
            <div class="col-12 mb-3">
                <div class="form-group">
                    <label for="">Instruction</label>
                    <p>{{selected_data.transmission?.instruction}}</p>
                </div>
            </div>
            <div class="col-12 mb-3">
                <div class="form-group">
                    <label for="">Delai</label>
                    <p>{{selected_data.transmission?.delay | date:"dd-MM-yyyy"}}</p>
                </div>
            </div>
         
        </div>
    </div>
</ng-template>