import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sampleSearch'
})
export class SampleSearchPipe implements PipeTransform {

  transform(value: any, searchText:String,type:String): any {

    return (searchText != '') ? value.filter((item:any) => {  
      switch(type){
        case 'registre':
         return this.getRegistreSearch(item,searchText)
        break;
      
        default:
          return value;
      }   
    }) : value;
  }

  getRegistreSearch(item:any,searchText:any){
    return item?.name?.toLowerCase().includes(searchText.toLowerCase()) 
          //  item?.is_active.includes(searchText.toLowerCase())
  }
 

}